export function clickMap(feature) {
    return {
      type: 'MAP_CLICK',
      payload: feature
    }
  }
  
  export function changeZone(selection) {
    return {
      type: 'ZONE_SELECT',
      payload: selection
    }
  }
  
  export function changePd(selection) {
    console.log('changePd action dispatching');
    return {
      type: 'OVERLAY_SELECT',
      payload: selection
    }
  }
  
  export function changeMaxVal(event) {
    return {
      type: 'MAX_VAL',
      payload: event.target.value
    }
  }
  
  export function changeMinVal(event) {
    return {
      type: 'MIN_VAL',
      payload: event.target.value
    }
  }
  
  export function loadSource(event) {
    return {
      type: 'LOAD_SOURCE',
      payload: event
    }
  }
  
  export function visualizeHeight(event) {
    return {
      type: 'VIZ_HEIGHT',
      payload: event.target.value
    }
  }
  
  export function visualizeColors(event) {
    return {
      type: 'VIZ_COLORS',
      payload: event.target.value
    }
  }
  
  export function filterSlopes(event) {
    // const convertedValue = event.target.value === 'true' ? true : false;
    return {
      type: 'FILTER_SLOPES',
      payload: event.target.checked
    }
  }
  
  export function filterTransit(event) {
    // const convertedValue = event.target.value === 'true' ? true : false;
    return {
      type: 'FILTER_TRANSIT',
      payload: event.target.checked
    }
  }
  
  export function filterFlood(event) {
    // const convertedValue = event.target.value === 'true' ? true : false;
    return {
      type: 'FILTER_FLOOD',
      payload: event.target.checked
    }
  }
  
  export function changeUse(event) {
    console.log(event.target.value);
    return {
      type: 'CHANGE_USE',
      payload: event.target.value
    }
  }
  
  export function changeHeightVal(event) {
    console.log('changing height');
    return {
      type: 'CHANGE_HEIGHT_VAL',
      payload: event.target.value
    }
  }
  
  export function changeLandVal(event) {
    console.log('changing land value');
    return {
      type: 'CHANGE_LAND_VAL',
      payload: event.target.value
    }
  }
  