import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Immutable from 'immutable';

import { changeLandVal } from '../../actions/index';

class FilterLandVal extends Component {

  render() {
    return (
      <div id='value-filter'>
        <span>Select Maximum Land Value</span><br />
        <input
          type='text'
          name='valueMax'
          value={ this.props.maxLandVal }
          onChange={ this.props.changeLandVal } />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeLandVal: changeLandVal
  },dispatch);
}
function mapStateToProps(state) {
  return{
    maxLandVal: state.userInterface.get('max_landVal')
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(FilterLandVal);
