function popupCreator(e) {
  if(!e) return null;

  var far;
  if (e.properties["maxResFAR"] != undefined){
    far = e.properties["maxResFAR"] + ' (Residential)';
  } else if (e.properties["baseFAR"] == 0){
    far = 'Unlimited';
  } else if(e.properties["baseFAR"] == undefined){
    far = 'N/A';
  } else {
    far = e.properties["baseFAR"];
  }

  var breakHtml = '<br />';
  var siteArea = 'Site Area: ' + e.properties["AREA"] + breakHtml;
  var rno = 'RNO: ' + e.properties["RNO"] + breakHtml;
  var bonusFAR = 'Detailed FAR: ' + far + breakHtml;
  var hist = 'Historical District: ' + (e.properties["hist"] ? e.properties["hist"] + breakHtml : 'None' + breakHtml);
  var split = 'Lot is split: ' + (e.properties["is_split"] == 1 ? 'Yes' + breakHtml : 'No' + breakHtml);
  var maxResFAR = 'Max Residential FAR: ' + e.properties["maxResFAR"] + breakHtml;
  var ovrly = 'Overlay Districts: ' + (e.properties["ovrly"] ? e.properties["ovrly"] + breakHtml : 'None' + breakHtml);
  var pldist = 'Plan District: ' + (e.properties["pldist"] ? e.properties["pldist"] + breakHtml : 'None' + breakHtml);
  var baseZone = 'Base Zone: ' + e.properties["zone"] + breakHtml;
  var owner = 'Owner: ' + e.properties["OWNER"] + breakHtml;
  var availFAR = 'Available FAR: ' + e.properties["AVAIL_FAR"] + breakHtml;

  var html = baseZone + pldist + ovrly + hist + split + bonusFAR + availFAR + rno + siteArea + owner;
  return html;
}

export default popupCreator;
