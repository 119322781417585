import Immutable from 'immutable';
import { zoneColors } from '../data/zoneColors';

export default function StylesheetReducer(styleState = null, action) {
  switch(action.type){
    case 'MAP_CLICK': {
      // This action will cause a feature to be highlighted.
      const LAYER_ID = 'selected_taxlot';
      const lotID = action.payload[0] ? action.payload[0].properties.RNO : '';
      const layerIdx = styleState.get('layers').findIndex(layer => layer.get('id') === LAYER_ID);
      const newStyle = styleState.updateIn(['layers', layerIdx], layer => {
        return layer.set('filter',['==','RNO', lotID]);
      });

      return newStyle;
    }

    case 'ZONE_SELECT': {
      // filters down to a specific set of zones
      // "filter": ["all", ["has","zone"], ["all", [">=","AREA",0], [">=","AREA",0]] ]
      const selectedZones = action.payload.map(e => e.value);
      let newZoneFilter = ['any'];
      for (var i = 0; i < selectedZones.length; i++) {
        newZoneFilter.push( ['==', 'zone', selectedZones[i]] );
      }
      if(newZoneFilter.length == 1) newZoneFilter = ['has','zone'];

      const LAYER_ID = 'taxlots';
      const layerIdx = styleState.get('layers').findIndex(layer => layer.get('id') === LAYER_ID);
      const newStyle = styleState.updateIn(['layers', layerIdx, 'filter'], filter => {
        return filter.set(1, newZoneFilter);
      });
      return newStyle;
    }

    case 'OVERLAY_SELECT': {
      console.log('overlay select reducer activated');
      const selectedPD = action.payload.map(e => e.value);
      let newPDFilter = ['any'];
      for (var i = 0; i < selectedPD.length; i++) {
        newPDFilter.push( ['==', 'PLDIST', selectedPD[i]] );
      }
      if(newPDFilter.length == 1) newPDFilter = ['!has','PLDIST'];

      const LAYER_ID = 'plan_districts';
      const layerIdx = styleState.get('layers').findIndex(layer => layer.get('id') === LAYER_ID);
      const newStyle = styleState.updateIn(['layers', layerIdx, 'filter'], filter => {
        return filter.set(1, newPDFilter);
      });
      console.log('=======================');
      console.log('OVERLAY READY TO RETURN');
      console.log(newStyle);
      console.log('=======================');
      return newStyle;
    }

    case 'LOAD_SOURCE': {
      console.log('load source reducer activated');
      const { name, data } = action.payload;
      console.log(name);
      const newStyle = styleState.setIn(['sources', name, 'data'], data);
      console.log('=======================');
      console.log('LOAD SOURCE READY TO RETURN');
      console.log(newStyle.toJS());
      console.log('=======================');
      return newStyle;
    }

    case 'MAX_VAL': {
      const LAYER_ID = 'taxlots';
      const FILTER_IDX = [2,2];
      const maxValue = parseFloat(action.payload) || 0;
      const layerIdx = styleState.get('layers').findIndex(layer => layer.get('id') === LAYER_ID);
      const lesser = maxValue > 0 ? ['<','AREA', maxValue] : ['has','AREA'];
      const newStyle = styleState.updateIn(['layers', layerIdx, 'filter'], filter => {
        return filter.setIn(FILTER_IDX, lesser);
      });
      return newStyle;
    }

    case 'MIN_VAL': {
      const LAYER_ID = 'taxlots';
      const FILTER_IDX = [2,1];
      const minValue = parseFloat(action.payload) || 0;
      const layerIdx = styleState.get('layers').findIndex(layer => layer.get('id') === LAYER_ID);
      const greater = ['>=', 'AREA', minValue];
      const newStyle = styleState.updateIn(['layers', layerIdx, 'filter'], filter => {
        return filter.setIn(FILTER_IDX, greater);
      });
      return newStyle;
    }

    case 'CHANGE_HEIGHT_VAL': {
      const LAYER_ID = 'taxlots';
      const FILTER_IDX = [3];
      const height = parseFloat(action.payload) || 0;
      const layerIdx = styleState.get('layers').findIndex(layer => layer.get('id') === LAYER_ID);
      const newFilter = ['>=', 'maxHeight', height];
      const newStyle = styleState.updateIn(['layers', layerIdx, 'filter'], filter => {
        return filter.setIn(FILTER_IDX, newFilter);
      });
      return newStyle;
    }

    case 'CHANGE_LAND_VAL': {
      const LAYER_ID = 'taxlots';
      const FILTER_IDX = [7];
      const value = parseFloat(action.payload) || 0;
      const layerIdx = styleState.get('layers').findIndex(layer => layer.get('id') === LAYER_ID);
      const newFilter = value > 0 ? ['<=', 'LANDVAL', value] : ['has', 'LANDVAL'];
      const newStyle = styleState.updateIn(['layers', layerIdx, 'filter'], filter => {
        return filter.setIn(FILTER_IDX, newFilter);
      });
      return newStyle;
    }

    case 'FILTER_SLOPES': {
      const LAYER_ID = 'taxlots';
      const FILTER_IDX = [6];
      const layerIdx = styleState.get('layers').findIndex(layer => layer.get('id') === LAYER_ID);
      const newFilter = action.payload === true ? ['has', 'twelveSlop'] : ['<', 'twelveSlop', 12.0];
      const newStyle = styleState.updateIn(['layers', layerIdx, 'filter'], filter => {
        return filter.setIn(FILTER_IDX, newFilter);
      });
      return newStyle;
    }

    case 'FILTER_TRANSIT': {
      const LAYER_ID = 'taxlots';
      const FILTER_IDX = [5];
      const layerIdx = styleState.get('layers').findIndex(layer => layer.get('id') === LAYER_ID);
      const newFilter = action.payload === true ? ['has', 'trans_qtr'] : ['==', 'trans_qtr', 1];
      const newStyle = styleState.updateIn(['layers', layerIdx, 'filter'], filter => {
        return filter.setIn(FILTER_IDX, newFilter);
      });
      return newStyle;
    }

    case 'FILTER_FLOOD': {
      const LAYER_ID = 'taxlots';
      const FILTER_IDX = [4];
      const layerIdx = styleState.get('layers').findIndex(layer => layer.get('id') === LAYER_ID);
      const newFilter = action.payload === true ? ['has', 'floodPlane'] : ['==', 'floodPlane', 0];
      const newStyle = styleState.updateIn(['layers', layerIdx, 'filter'], filter => {
        return filter.setIn(FILTER_IDX, newFilter);
      });
      return newStyle;
    }

    case 'VIZ_HEIGHT': {
      const LAYER_ID = 'taxlots';
      const layerIdx = styleState.get('layers').findIndex(layer => layer.get('id') === LAYER_ID);

      let heightObj = { 'property':'', stops:[] };
      switch(action.payload) {
        case 'far':
          heightObj.property = 'baseFAR';
          heightObj.stops = [[0,0],[1,15],[15,225]];
          break;
        case 'avail':
          heightObj.property = 'AVAIL_FAR';
          heightObj.stops = [[0,0],[1,15],[15,225]];
          break;
        case 'height':
          heightObj.property = 'maxHeight';
          heightObj.stops = [[0,0],[225,68.58]];
          break;
        default:
          heightObj.property = 'baseFAR';
          heightObj.stops = [[0,0],[1,0],[15,0]];
      }

      const newStyle = styleState.updateIn(['layers', layerIdx, 'paint'], paint => {
        return paint.set('fill-extrusion-height', heightObj);
      });
      return newStyle;
    }

    case 'VIZ_COLORS': {
      const LAYER_ID = 'taxlots';
      const layerIdx = styleState.get('layers').findIndex(layer => layer.get('id') === LAYER_ID);
      let colorObj;

      switch(action.payload) {
        case 'zone':
          colorObj = { "type":"categorical", "property": "zone", "stops": zoneColors };
          break;
        default:
          colorObj = '#FF0000';
      }

      const newStyle = styleState.updateIn(['layers', layerIdx, 'paint'], paint => {
        return paint.set('fill-extrusion-color', colorObj);
      });
      return newStyle;
    }

    default: return styleState;
  }
}
