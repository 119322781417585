import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { visualizeHeight } from '../../actions/index';

class VizHeight extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div id='height-source'>
        <span>Height</span><br />
        <div style={{ float:'left', marginLeft: '10px' }}>
          <input
            type='checkbox'
            value='far'
            checked={ this.props.far }
            onChange= { this.props.visualizeHeight } /> FAR
        </div>
        {/* <div style={{ float:'left', marginLeft: '10px' }}>
          <input
            type='checkbox'
            value='avail'
            checked={ this.props.avail }
            onChange= { this.props.visualizeHeight } /> Available FAR
        </div> */}
        <div style={{ float:'left', marginLeft: '10px' }}>
          <input
            type='checkbox'
            value='height'
            checked={ this.props.height }
            onChange= { this.props.visualizeHeight } /> Max Height
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    visualizeHeight: visualizeHeight
  },dispatch);
}
function mapStateToProps(state) {
  return{
    far: state.userInterface.get('vizHeight_far'),
    avail: state.userInterface.get('vizHeight_avail'),
    height: state.userInterface.get('vizHeight_height')
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(VizHeight);
