/* global mapboxgl */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Immutable from 'immutable';
import { json as requestJson } from 'd3-request';

import { clickMap, loadSource } from '../actions/index';
import diffStyles from '../utils/diff.js';

class _RMap extends Component {

  componentDidMount(){
    const { token, longitude, latitude, zoom, minZoom } = this.props;
    console.log(this.props.mapStyle.toJS())

    console.log('map mounted');
    const mapConfig = {
      container: 'map',
      style: this.props.mapStyle.toJS(),
      center: [longitude, latitude],
      zoom: zoom,
      minZoom: minZoom
    };
    if(this.props.pitch) mapConfig['pitch'] = this.props.pitch;
    if(this.props.bearing) mapConfig['bearing'] = this.props.bearing;

    mapboxgl.accessToken = token;
    this.map = new mapboxgl.Map(mapConfig);

    this.map.on('load', () => {
      this.map.on('click', e => {
        const features = this.map.queryRenderedFeatures(e.point);
        const currentLayer = this.props.userInterface.get('currentLayer');
        const selectedFeature = features.filter(f => f.layer.id === currentLayer);
        this.props.clickMap(selectedFeature);
        if(this.props.showPopUp && this.props.userInterface.get('popup') != null) {
          new mapboxgl.Popup()
            .setLngLat(e.lngLat)
            .setHTML(this.props.userInterface.get('popup'))
            .addTo(this.map);
        }
      });

      requestJson('https://raw.githubusercontent.com/AnkromMoisan/Presentations/master/planDistrict.geojson', (err, res) => {
        console.log('json returned');
        if (err) console.log(err);
        this.props.loadSource({ name: "pd-source", data: res });
      });

      const features = this.map.querySourceFeatures('taxlot-source',{ sourceLayer: ['zone_w_fargeojson'] });
      const allFAR = features.map(feature => feature.properties.baseFAR);

      console.log(allFAR);
    });
  }

  componentWillReceiveProps(nextProps) {
    const before = this.props.mapStyle;
    const after = nextProps.mapStyle;
    const map = this.map;
    console.log(after.toJS());

    if(!Immutable.is(before, after)) {
      const changes = diffStyles(before.toJS(), after.toJS());
      console.log('updating style');
      changes.forEach(change => {
        if(change.command == 'updateSource'){
          // This is a workaround patch for updateSource not being
          // low level enough for a generic apply command
          map.getSource(change.args[0]).setData(change.args[1].data);
        } else {
          console.log(change);
          map[change.command].apply(map, change.args);
        }
      });
    }
  }

  render(){
    return (
      <div id='map'></div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    clickMap: clickMap,
    loadSource: loadSource
  }, dispatch);
}
function mapStateToProps(state) {
  return {
    mapStyle: state.mapStyle,
    userInterface: state.userInterface
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(_RMap);
