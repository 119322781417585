import React,{Component} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Immutable from 'immutable';

import { changeMinVal, changeMaxVal } from '../../actions/index';

class FilterSize extends Component {

  render() {
    return (
      <div id='size-filter'>
        <span>Select Size (sf)</span><br />
        <input
          type='text'
          name='sizeMin'
          value={ this.props.minValue }
          style={{ width: '49%' }}
          onChange={ this.props.changeMinVal } />
        <input
          type='text'
          name='sizeMax'
          value={ this.props.maxValue }
          style={{ width: '49%' }}
          onChange={ this.props.changeMaxVal } />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeMinVal: changeMinVal,
    changeMaxVal: changeMaxVal
  },dispatch);
}
function mapStateToProps(state) {
  return{
    minValue: state.userInterface.get('min_val'),
    maxValue: state.userInterface.get('max_val')
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(FilterSize);
