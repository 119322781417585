import React,{Component} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Immutable from 'immutable';

import { filterSlopes, filterTransit, filterFlood } from '../../actions/index';

class FilterBoolean extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div id='boolean-filters'>
        <div id='floodplane'>
          {/* <span>Show sites in flood-plane?</span><br /> */}
          <div style={{ float: 'left', marginRight: '10px' }}>
            <input
              type='checkbox'
              value='false'
              name='floodFilter'
              checked={ this.props.floodCheck }
              onChange={ this.props.filterFlood } />
          </div>Show sites in flood-plane.
        </div>
        <br />

        <div id='slopes'>
          {/* <span>Show sites > 12% slope?</span><br /> */}
          <div style={{ float: 'left', marginRight: '10px' }}>
            <input
              type='checkbox'
              value='false'
              name='slopeFilter'
              checked={ this.props.slopeCheck }
              onChange={ this.props.filterSlopes } />
          </div>Show sites > 12% slope.
        </div>
        <br />

        <div id='transit-adjacent'>
          {/* <span>Show sites > 1/4 mile from transit?</span><br /> */}
          <div style={{ float: 'left', marginRight: '10px' }}>
            <input
              type='checkbox'
              value='false'
              name='transitFilter'
              checked={ this.props.transitCheck }
              onChange={ this.props.filterTransit } />
          </div>Show sites > 1/4 mile from transit.
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    filterSlopes: filterSlopes,
    filterTransit: filterTransit,
    filterFlood: filterFlood
  },dispatch);
}
function mapStateToProps(state) {
  return{
    slopeCheck: state.userInterface.get('filter_slope'),
    transitCheck: state.userInterface.get('filter_transit'),
    floodCheck: state.userInterface.get('filter_flood')
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(FilterBoolean);
