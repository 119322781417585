import Immutable from 'immutable';
import popupCreator from '../utils/popup';

export default function UserIntReducer(userIntState = null, action) {
  switch(action.type){
    case 'LAYER_CHANGE':
      return userIntState.update('currentLayer', layer => action.payload.layerName);
    case 'MAP_CLICK':
      const markup = popupCreator(action.payload[0]);
      return userIntState.update('popup', html => markup);
    case 'ZONE_SELECT':
      return userIntState.set('select_val', action.payload);
    case 'OVERLAY_SELECT':
      return userIntState.set('overlay_pd', action.payload);
    case 'MAX_VAL':
      return userIntState.set('max_val', action.payload);
    case 'MIN_VAL':
      return userIntState.set('min_val', action.payload);
    case 'CHANGE_USE':
      return userIntState.set('use', action.payload);
    case 'CHANGE_HEIGHT_VAL':
      return userIntState.set('max_height', action.payload);
    case 'CHANGE_LAND_VAL':
      return userIntState.set('max_landVal', action.payload);
    case 'VIZ_HEIGHT': {
      const far = action.payload === 'far' ? true : false;
      const avail = action.payload === 'avail' ? true : false;
      const height = action.payload === 'height' ? true : false;
      return userIntState.set('vizHeight_far', far)
        .set('vizHeight_avail', avail)
        .set('vizHeight_height', height);
    }
    case 'VIZ_COLORS': {
      const zone = action.payload === 'zone' ? true : false;
      const none = action.payload === 'none' ? true: false;
      return userIntState.set('vizColor_zone', zone).set('vizColor_none', none);
    }
    case 'FILTER_SLOPES': {
      return userIntState.set('filter_slope', action.payload);
    }
    case 'FILTER_TRANSIT': {
      return userIntState.set('filter_transit', action.payload);
    }
    case 'FILTER_FLOOD': {
      return userIntState.set('filter_flood', action.payload);
    }
    default: return userIntState;
  }

  return userIntState;
}
