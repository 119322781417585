// import ReactDOM from 'react-dom';
import React, { Component } from 'react';
import RMap from '../RMap/RMap.jsx';
import Interface from '../Interface/Interface.jsx';

const TOKEN = 'pk.eyJ1IjoicnlhbnRtIiwiYSI6ImNpaDgycjExZzB0NDR1MWtpbWdkeDhxbmIifQ.AamjhGik8yPxK5V71kzHdw';
const LONG = -122.66661759147235;
const LAT = 45.51886025215052;
const ZOOM = 14.26;
const MIN_ZOOM = 13;

export default class App extends Component {
  render() {
    console.log('App loading')
    return (
      <div>
        <Interface />
        <RMap
          token= { TOKEN }
          longitude= { LONG }
          latitude= { LAT }
          zoom= { ZOOM }
          showPopUp= { true }
          minZoom = { MIN_ZOOM }
        />
      </div>
    );
  }
}
