export const zoneColors = [
  [ 'RF',  '#2242EE' ],
  [ 'R20',  '#5342E8' ],
  [ 'R10',  '#566EFF' ],
  [ 'R7',  '#4283E8' ],
  [ 'R5',  '#4BBCFF' ],
  [ 'R2.5', '#40AFFF' ],
  [ 'R1',  '#2EFFAB' ],
  [ 'R2',  '#2AE86A' ],
  [ 'R3',  '#3BFF4A' ],
  [ 'RH',  '#54E82A' ],
  [ 'RX',  '#A0FF30' ],
  [ 'CN1',  '#FFEF33' ],
  [ 'CN2',  '#E8C82E' ],
  [ 'CO1',  '#FFCD40' ],
  [ 'CO2',  '#E8A62E' ],
  [ 'CM',  '#FFA135' ],
  [ 'CS',  '#FF863B' ],
  [ 'CG',  '#E8592A' ],
  [ 'CX',  '#FF4830' ],
  [ 'EG1',  '#EE34A0' ],
  [ 'EG2',  '#E82FD8' ],
  [ 'EX',  '#E141FF' ],
  [ 'IG1',  '#A42FE8' ],
  [ 'IG2',  '#8933FF' ],
  [ 'IH',  '#8753FF' ]
];
