import React, { Component } from 'react';

import FilterZone from './components/FilterZone';
import FilterSize from './components/FilterSize';
import FilterHeight from './components/FilterHeight';
import FilterLandVal from './components/FilterLandVal';
import FilterBoolean from './components/FilterBoolean';
// import OverlayPD from './components/OverlayPD';
import VizHeight from './components/VizHeight';
import VizColor from './components/VizColor';

export default class Interface extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div id='ui' style={ STYLE.ui }>
        <div className='filters' id='filter-div'>
          <label>Filters:</label><br />
          <FilterZone /><br />
          <FilterSize /><br />
          <FilterHeight /><br />
          <FilterLandVal /><br />
          <FilterBoolean /><br />
        </div>
        <br /><br />
        {/* <div className='overlay' id='overlay-div'>
          <label>Overlays: </label><br />
          <OverlayPD />
        </div>
        <br /><br /> */}
        <div className='viz' id='viz-div'>
          <label>Visualization: </label><br />
          <VizHeight /><br /><br />
          <VizColor />
        </div>
      </div>
    );
  }
}

const STYLE = {
  ui: {
    zIndex: 2,
    position: 'absolute',
    top: '0px',
    bottom: '0px',
    right: '0px',
    borderRadius: '7px',
    width: '400px',
    background: 'white',
    padding: '15px'
  }
};
