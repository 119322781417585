import Immutable from 'immutable';
import MapStyle from '../mapstyles/base/dark.json';

function hydrate(usePrevious = null) {
  if(!usePrevious){
    return {
      mapStyle: Immutable.fromJS(MapStyle),
      userInterface: Immutable.fromJS({
        currentLayer: 'taxlots',
        popup: '<div></div>',
        select_val: null,
        overlay_pd: null,
        max_val: 'max',
        min_val: 'min',
        max_height: '(0 = All)',
        max_landVal: '(0 = All)',
        use: 'any',
        vizHeight_far: true,
        vizHeight_avail: false,
        vizHeight_height: false,
        vizColor_zone: true,
        vizColor_none: false,
        filter_slope: true,
        filter_transit: true,
        filter_flood: true
      })
    };
  }
}

const InitialState = hydrate();
export default InitialState;