import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { visualizeColors } from '../../actions/index';

class VizColor extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div id='color-source'>
        <span>Color by</span><br />
        <div style={{ float:'left', marginLeft: '10px' }}>
          <input
            type='checkbox'
            value='zone'
            checked={ this.props.zone }
            onChange={ this.props.visualizeColors }/> Zone
        </div>
        <div style={{ float:'left', marginLeft: '10px' }}>
          <input
            type='checkbox'
            value='none'
            checked={ this.props.none }
            onChange={ this.props.visualizeColors }/> None
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    visualizeColors: visualizeColors
  },dispatch);
}
function mapStateToProps(state) {
  return{
    zone: state.userInterface.get('vizColor_zone'),
    none: state.userInterface.get('vizColor_none')
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(VizColor);
