import React,{Component} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Immutable from 'immutable';
import Select from 'react-select';
import intersection from 'lodash.intersection'

import { zoneOptions, presets } from '../../data/zones';
import { changeZone } from '../../actions/index';

class FilterZone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      presets: {
        any: true,
        residential: false,
        office: false,
        retail: false
      }
    };
  }

  _createPresets() {
    const checkboxes = Object.keys(presets).map(e => {
      return (
        <div style={{ float:'left', marginLeft: '10px' }} key={ 'preset_' + e }>
          <input
            type='checkbox'
            value= { e }
            checked={ this.state.presets[e] }
            onChange={ this._presetUses.bind(this) } /> { e }
        </div>
      );
    });
    return (
      <div>
        { checkboxes }
      </div>
    );
  }

  _presetUses(event) {
    // TODO: This is a really fucked up way to do this whole function...
    if(event.target.value === 'any') {
      this.setState({ presets: { any: true, residential: false, office: false, retail: false } });
      this.props.changeZone(presets.any);
    } else {
      const newState = this.state.presets;
      newState[event.target.value] = !newState[event.target.value];
      newState.any = false;

      // If all have been unchecked, reset any to true
      if(Object.values(newState).reduce((p,c) => p+c) === 0) {
        this.setState({ presets: { any: true, residential: false, office: false, retail: false } });
        this.props.changeZone(presets.any);
      } else {
        // Perform a logical intersect of presets (return zones appearing in all presets)
        console.log('STATE',newState)
        const activeZones = Object.keys(newState)
          .filter(key => newState[key] === true)
          .map(key => presets[key].map(obj => obj.value))
          .reduce((p,c) => intersection(p,c))
          .map(zone => { return { label: zone, value: zone } })

        this.setState({ presets: newState })
        this.props.changeZone(activeZones);
      }
    }
  }

  render() {
    return (
      <div id='zoning'>
        <div id='use-filter' style={{ marginBottom: '20px' }}>
          <span>Allowed Use Presets</span><br />
          { this._createPresets() }
        </div>
        <br />
        <div id='zone-filter'>
          <span>Select Zone</span>
          <Select
            isMulti
            name="form-field-name"
            value={ this.props.select_val }
            options={ zoneOptions }
            onChange={ this.props.changeZone } />
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeZone: changeZone
  },dispatch);
}
function mapStateToProps(state) {
  return{
    select_val: state.userInterface.get('select_val')
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(FilterZone);
